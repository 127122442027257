@import '~bootstrap-4-grid/css/grid.css';

@font-face {
    font-family: Montserrat;
    src: url('../../../public/shared/assets/fonts/subset-Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../public/shared/assets/fonts/subset-Montserrat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../public/shared/assets/fonts/subset-Montserrat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../public/shared/assets/fonts/subset-Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../public/shared/assets/fonts/subset-Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../public/shared/assets/fonts/subset-Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../public/shared/assets/fonts/subset-Montserrat-Regular-M.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../public/shared/assets/fonts/subset-Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url('../../../public/shared/assets/fonts/subset-Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
